import {useIndexStore} from "../store";

export function useUserNav() {
    const {$web, $app, $device, $auth} = useNuxtApp();
    const config = useRuntimeConfig();
    const {t, localeProperties} = useI18n();
    const indexStore = useIndexStore();

    const userNavItems = computed(() => {

        // We pass the auth token for links to our external services to keep the user authenticated
        let authParam = '';
        if ($device.isNative && $auth.loggedIn) {
            authParam = `?auth=${$auth.token}`
        }

        const items = [];

        if ($auth.loggedIn) {
            items.push({name: t('label.my_profile'), to: $web('users.show.index', $auth.user?._key ?? 'my'), exact: true})
            items.push({name: t('label.my_events'), to: $web('my.events.index')})
            items.push({name: t('label.my_followings'), to: $web('my.followings.index')})
            if ($auth.user?.has_children) {
                items.push({name: t('label.my_children'), to: $web('my.profile.manage.children')})
            }
            items.push({name: t('label.my_memberships'), to: $web('my.memberships.index')})
            items.push({name: t('label.settings'), to: $web('my.profile.manage.settings')})

            const isAdmin = $auth.user?.is_admin;
            const isOrganizer = $auth.user?.roles.includes('event');
            if (isAdmin || isOrganizer) {
                items.push({divider: true});
                if (isAdmin) {
                    items.push({name: t('label.admin_area'), to: `${config.public.adminUrl}${authParam}`})
                }
                if (isOrganizer) {
                    items.push({name: t('label.resultastic'), to: `${config.public.resultasticUrl}${authParam}`})
                }
            }

            items.push({divider: true});
            items.push({name: t('label.support'), to: $web('support')})
            items.push({name: t('label.privacy'), to: $web('privacy')})
            items.push({name: t('label.imprint'), to: $web('imprint')})
            items.push({divider: true});

            items.push({name: t('action.switch_language'), suffix_image: {src: localeProperties.value.flag, rounded: true}, callback: () => indexStore.langModal = true})

            if ($auth.isImpersonating) {
                items.push({name: `${t('action.reset')}: ${$auth.originalUserName}`, callback: $auth.stopImpersonate, color: config.public.colors.warning})
            }

            items.push({name: t('action.logout'), callback: () => $auth.logout('user_nav')})
        } else {
            items.push({name: t('label.competition_calendar'), to: $web('events.index')})
            items.push({name: t('label.club_map'), to: $web('clubs.index')})
            items.push({name: t('label.sports_map'), to: $web('events.sports_map')})
            items.push({name: t('label.sports_directory'), to: $app('sports')})
            items.push({name: t('label.athletes'), to: $web('users.index')})
            items.push({name: t('label.job_portal'), to: $web('jobs.index')})

            items.push({divider: true});

            items.push({name: t('label.about_spoferan'), to: config.public.aboutUrl});
            items.push({name: t('label.support'), to: $web('support')})
            items.push({name: t('label.contact'), to: $web('contact')})
            items.push({name: t('label.privacy'), to: $web('privacy')})
            items.push({name: t('label.imprint'), to: $web('imprint')})
        }

        return items;
    });

    return {
        userNavItems
    }
}