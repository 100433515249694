<template>
	<header class="app-header app-header-transition h-56 left-0 right-0 w-full z-50 bg-white fixed"
			:class="[props.background ? 'bg-gray-100' : 'bg-white', {'is-hidden': !isActive && scrollPosition < 150, 'shadow': !isFlat}]"
			:style="{top: isActive ? 0 : '-56px'}"
			v-scroll="{enabled: !$device.isDesktop, handler: handleScroll}">
		<container class="flex items-center justify-between h-full">
			<div v-if="props.main || $device.isDesktop" class="flex-grow-x-1 pr-5">
				<nuxt-link :to="$auth.loggedIn ? '/dashboard' : '/'">
					<cdn-image v-if="props.title" :src="mobileLogo.path" :height="24" :ratio="mobileLogo.ratio" alt="SPOFERAN" immediate class="md:none min-w-24"/>
					<cdn-image :src="logo.path" :height="28" :ratio="logo.ratio" alt="SPOFERAN" immediate class="min-w-160" :class="{'none md:flex': props.title}"/>
				</nuxt-link>
			</div>
			<btn v-else icon text rounded-full :aria-label="$t('action.back')" @click="$router.back()" exact class="ml--8">
				<icon size="32" force-size :icon="icons.back"/>
			</btn>

			<container class="overflow-hidden" tight custom-padding="pl-8 pr-16 sm:pl-16">
				<div class="flex items-center justify-between overflow-hidden"
					 :class="[{'md:border-l': props.title}, props.image ? 'pl-2 md:pl-16' : 'pl-2 md:pl-24 lg:pl-48']">
					<div class="flex items-center h-full overflow-hidden">
						<template v-if="props.title">
							<template v-if="props.image">
								<nuxt-link v-if="props.to" :to="props.to" replace>
									<avatar :src="props.image.path" :round="props.image.round" :size="32" class="mr-12 sm:mr-16"/>
								</nuxt-link>
								<avatar v-else :src="props.image.path" :round="props.image.round" :size="32" class="mr-12 sm:mr-16"/>
							</template>
							<div class="flex flex-column overflow-hidden">
								<p v-if="props.breadcrumbs" class="text-3xs text-uppercase truncate leading-spaced mb-0 font-light-bold font-text">
									<lazy-breadcrumbs :items="props.breadcrumbs" no-wrap color="text-color"/>
								</p>
								<component v-if="props.title" :is="props.heading ? 'h1' : 'p'"
										   class="truncate mb-0 font-text leading-spaced font-light-bold"
										   :class="[props.breadcrumbs ? 'text-sub' : 'text-md']" :style="{color: props.color}">
									<nuxt-link v-if="props.to" :class="{'text-primary': !props.color}"
											   :style="{color: props.breadcrumbs ? props.color : null}" :to="props.to" replace>
										{{ props.title }}
									</nuxt-link>
									<template v-else>{{ props.title }}</template>
								</component>
							</div>
						</template>
					</div>
					<div v-if="!$auth.loggedIn && $device.isDesktop">
						<btn text nofollow color="primary" :to="{path: $web('auth.login'), query: {redirect: $route.fullPath}}">{{ $t('action.login') }}</btn>
					</div>
				</div>
			</container>

			<div class="h-full flex-grow-x-1 flex items-center justify-end">
				<template v-if="$device.isDesktop">
					<tooltip bottom :text="$t('label.dashboard')" class="h-full items-center justify-center flex relative">
						<btn small icon rounded-full class="bg-gray-300" :aria-label="$t('label.dashboard')" :to="$web('dashboard')">
							<icon :icon="icons.dashboard" color="white" :size="18" force-size/>
						</btn>
					</tooltip>

					<tooltip bottom :text="$t('label.search')" class="h-full items-center justify-center flex relative ml-12 sm:ml-16">
						<btn small icon rounded-full class="bg-gray-300" :aria-label="$t('label.search')" :to="$web('search')">
							<icon :icon="icons.search" color="white" :size="18" force-size/>
						</btn>
					</tooltip>

					<tooltip bottom :text="$t('label.notifications')" class="h-full relative ml-12 sm:ml-16">
						<notification-dropdown class="h-full flex items-center"
											   :requires-interaction="($auth.user && !$auth.user.is_confirmed) || !indexStore.hasConfiguredPushNotifications">

							<template v-if="indexStore.hasConfiguredPushNotifications && !indexStore.hasEnabledPushNotifications" #title_right>
								<chip small
									  :icon="icons.disabled_push"
									  color="warning"
									  to="/my/profile/manage/settings/notifications/devices">{{ $t('label.push_disabled') }}</chip>
							</template>

							<div v-if="$auth.user && !$auth.user.is_confirmed" class="px-16 mt-8">
								<lazy-confirm-account-alert hide-icon/>
							</div>
							<div v-else-if="$auth.loggedIn && !indexStore.hasConfiguredPushNotifications" class="px-16 mt-8">
								<lazy-enable-push-alert hide-icon/>
							</div>
						</notification-dropdown>
					</tooltip>

					<tooltip bottom :text="$t('label.my_profile')" class="h-full relative ml-12 sm:ml-16">
						<dropdown nudge-right min-width="260" class="h-full flex items-center" :actions="userNavItems">
							<div class="relative">
								<avatar :size="34" :model="$auth.user" fallback="/default/user-default-avatar-padded.png" round/>
								<badge v-if="$auth.isImpersonating" absolute right top rounded-full small color="secondary"/>
							</div>

							<template #title>
								<div class="flex px-16 pt-8 pb-16 mb-4 border-b">
									<template v-if="$auth.user">
										<nuxt-link :to="$spoferanUrl(`/athletes/${$auth.user._key}`)" class="mr-16">
											<avatar :size="34" :model="$auth.user" round/>
										</nuxt-link>

										<div class="flex flex-column justify-center">
											<nuxt-link :to="$spoferanUrl(`/athletes/${$auth.user._key}`)" class="text-sm mb-4 text-heading-color">{{ $auth.user.display_name }}</nuxt-link>
											<chip :to="$spoferanUrl('/my/finisherpoints')" x-small>{{ n($auth.user.finisherpoints) }} {{ $t('label.finisherpoints') }}</chip>
										</div>
									</template>
									<wrapper v-else grow>
										<btn :to="{path: $spoferanUrl('/login'), query: {redirect: $route.fullPath}}" rounded-large text color="primary">{{ $t('action.login') }}</btn>
										<btn :to="{path: $spoferanUrl('/register'), query: {redirect: $route.fullPath}}" rounded-large color="primary">{{ $t('action.create_account') }}</btn>
									</wrapper>
								</div>
							</template>

						</dropdown>
					</tooltip>
				</template>
				<template v-else>
					<chip v-if="itemLink" :to="itemLink.to" :color="itemLink.color" small replace exact>
						<span>{{ itemLink.text }}</span>
						<template #right>
							<icon :icon="icons.next" class="ml-4" x-small/>
						</template>
					</chip>
					<div v-else-if="!$auth.loggedIn && !$slots.default" class="mr--16">
						<btn text nofollow color="primary" :to="{path: $web('auth.login'), query: {redirect: $route.fullPath}}">{{ $t('action.login') }}</btn>
					</div>
					<slot/>
				</template>
			</div>
		</container>
	</header>
</template>

<script setup lang="ts">
import {useIndexStore} from "../../store";
import {useUserNav} from "../../composables/useUserNav";
import {mdiAccount, mdiBellOffOutline, mdiHome} from "@mdi/js";
import {Images} from "@spoferan/spoferan-ts-core";
import {backIcon, nextIcon} from "@spoferan/nuxt-spoferan/icons";
import {searchIcon} from "@spoferan/nuxt-spoferan/icons";

const indexStore = useIndexStore();
const {userNavItems} = useUserNav();
const {$device} = useNuxtApp();
const {n} = useI18n();

const icons = {
	dashboard: mdiHome,
	account: mdiAccount,
	back: backIcon,
	next: nextIcon,
	search: searchIcon,
	disabled_push: mdiBellOffOutline,
};

const logo = Images.logo;
const mobileLogo = Images.favicon;

const props = withDefaults(defineProps<{
	modelValue?: boolean,
	title?: string,
	to?: string | object,
	color?: string,
	image?: object,
	breadcrumbs?: { to?: string, text: string, exact?: boolean }[],
	itemLink?: { to: string, text: string, color?: string },
	heading?: boolean, // Whether the title tag is generated as h1 heading tag
	main?: boolean, // Whether the header is shown on a main entry page, i.e., no back button will be displayed but a logo
	flatOnTop?: boolean, // Whether no shadow shall be shown when scrolled to the top of the page
	hideOnScroll?: boolean, // Whether to hide the header when scrolling to the bottom
	background?: boolean, // Whether a light gray background color shall be displayed instead of white
}>(), {
	heading: true,
	modelValue: true
});

const emit = defineEmits(['update:modelValue']);

// Keep the header visibility in sync
const isActive = ref(props.modelValue);
watch(isActive, (newValue) => {
	emit('update:modelValue', newValue);
});
watch(() => props.modelValue, (newValue) => {
	isActive.value = newValue;
});

const scrollPosition = ref(0);

const isFlat = computed(() => {
	return !$device.isDesktop && props.flatOnTop && scrollPosition.value < 10;
});

function handleScroll() {
	const currentScrollPosition = typeof window !== 'undefined' ? window.scrollY : 0;
	const scrollingUp = currentScrollPosition < scrollPosition.value;
	if (props.hideOnScroll) {
		isActive.value = (currentScrollPosition < 150 || scrollingUp);
	} else {
		isActive.value = true; // Required for route changes and resizing
	}

	scrollPosition.value = currentScrollPosition;
}

onMounted(() => {
	if (!$device.isDesktop) {
		handleScroll();
	}
})

</script>