/***********************************************************
 API Routes
 --------------------------
 In here, we define all our API routes to not access them in code
 by magic strings.
 ************************************************************/

export default {

    orders: {
        show: '/v1/orders/{order}',
        email: '/v1/orders/{order}/email',
        receipt: '/v1/orders/{order}/receipt',
        confirm: '/v1/orders/{order}/confirm',
        download_sepa: '/v1/orders/{order}/download-sepa',
        download: '/v1/orders/{order}/download',
        payment_methods: {
            index: '/v1/orders/{order}/payment-methods'
        },
        pay: '/v1/orders/{order}/pay',
        coupons: {
            update: '/v1/orders/{order}/coupons',
        },
        statements: {
            index: '/v1/orders/{order}/statements'
        },
        intents: {
            store: '/v1/orders/{order}/intents'
        },
        adjustments: {
            download: '/v1/orders/{order}/adjustments/{adjustment}/download'
        }
    },

    prices: {
        update: '/v1/prices/{price}',
        destroy: '/v1/prices/{price}'
    },

    cancel_conditions: {
        update: '/v1/cancel-conditions/{cancelCondition}',
        destroy: '/v1/cancel-conditions/{cancelCondition}'
    },

    events: {
        index: '/v1/events',
        store: '/v1/events/{event?}',
        update: '/v1/events/{event}',
        gtc: '/v1/events/{event}/gtc',
        copy: '/v1/events/{event}/copy',
        cancel: '/v1/events/{event}/cancel',
        destroy: '/v1/events/{event}',

        map: {
            index: '/v2/events/map',
            show: '/v1/events/map/{event}',
        },

        media: {
            purchases: {
                store: '/v1/events/{event}/galleries/media/purchases',
            }
        },

        participation_classes: {
            index: '/v1/events/{event}/participation-classes',
            store: '/v1/events/{event}/participation-classes',
            show: '/v1/events/{event}/participation-classes/{participationClass}',
            update: '/v1/events/{event}/participation-classes/{participationClass}',
            update_many: '/v1/events/{event}/participation-classes',
            cancel: '/v1/events/{event}/participation-classes/{participationClass}/cancel',
            destroy: '/v1/events/{event}/participation-classes/{participationClass}',
            download: '/v1/events/{event}/participation-classes/{participationClass}/download',
            copy: '/v1/events/{event}/participation-classes/{participationClass}/copy',

            starter_numbers: {
                assign: '/v1/events/{event}/participation-classes/{participationClass}/starter-numbers/assign',
            },

            track_spots: {
                index: '/v1/events/{event}/participation-classes/{participationClass}/track-spots',
            },

            tracks: {
                index: '/v1/events/{event}/participation-classes/{participationClass}/tracks',
            },

            age_classes: {
                resolve: '/v1/events/{event}/participation-classes/{participationClass}/age-classes/resolve'
            },
        },

        participation_lists: {
            index: '/v1/events/{event}/lists',
            download: '/v1/events/{event}/lists/{list}/download',

            participations: {
                index: '/v1/events/{event}/lists/{list}/participations',
                show: '/v1/events/{event}/lists/{list}/participations/{participation}',

                groups: {
                    show: '/v1/events/{event}/lists/{list}/participations/groups/{group}',
                }
            }
        },

        members: {
            index: '/v1/events/{event}/members',
            auth_requests: '/v1/events/{event}/members/requests/auth',
            store: '/v1/events/{event}/members',
            edit: '/v1/events/{event}/members/{member}/edit',
            update: '/v1/events/{event}/members/{member}',
            contact: '/v1/events/{event}/members/{member}/contact',
            destroy: '/v1/events/{event}/members/{member}/cancel',
            assign: '/v1/events/{event}/members/assign',

            join: {
                show: '/v1/events/{event}/members/join',
                resolve: '/v1/events/{event}/members/join/{code}'
            }
        },

        member_messages: {
            show: '/v1/events/{event}/member-messages/{message}'
        },

        pages: {
            show: '/v1/events/{event}/pages/{page}'
        },

        job_postings: {
            index: '/v1/events/{event}/job-postings',
            show: '/v1/events/{event}/job-postings/{posting}',
        },

        job_inputs: {
            index: '/v1/events/{event}/members/inputs',
        },

        ratings: {
            index: '/v1/events/{event}/ratings',
            store: '/v1/events/{event}/ratings',
            edit: '/v1/events/{event}/ratings/edit',
            update: '/v1/events/{event}/ratings/{rating}',
            destroy: '/v1/events/{event}/ratings/{rating}',
        },

        acquisition_campaigns: {
            index: '/v1/events/{event}/acquisition-campaigns',

            acquirers: {
                store: '/v1/events/{event}/acquisition-campaigns/{campaign}/acquirers',
            },
        },

        awards: {
            index: '/v1/events/{event}/awards',
            store: '/v1/events/{event}/awards',
            show: '/v1/events/{event}/awards/{award}',
            update: '/v1/events/{event}/awards/{award}',
            destroy: '/v1/events/{event}/awards/{award}',
        },

        result_list_groups: {
            index: '/v1/events/{event}/result-lists',
            show: '/v1/events/{event}/result-lists/{group}',
            download: '/v1/events/{event}/result-lists/{group}/download',

            ranks: {
                index: '/v1/events/{event}/result-lists/{group}/ranks',

                participations: {
                    index: '/v1/events/{event}/result-lists/{group}/ranks/{rank}/participations'
                },
            },

            result_lists: {
                ranks: {
                    participations: {
                        index: '/v1/events/{event}/result-lists/{group}/lists/{list}/ranks/{rank}/participations'
                    },
                }
            },
        },

        checklist_items: {
            index: '/v1/events/{event}/checklist-items',
            store: '/v1/events/{event}/checklist-items',
            show: '/v1/events/{event}/checklist-items/{item}',
            update: '/v1/events/{event}/checklist-items/{item}',
            destroy: '/v1/events/{event}/checklist-items/{item}',
        },

        certificates: {
            index: '/v1/events/{event}/certificates',
            store: '/v1/events/{event}/certificates',
            show: '/v1/events/{event}/certificates/{certificate}',
            download_preview: '/v1/events/{event}/certificates/{certificate}/download',
            download: '/v1/events/{event}/certificates/download',
            update: '/v1/events/{event}/certificates/{certificate}',
            destroy: '/v1/events/{event}/certificates/{certificate}',
        },

        certificate_templates: {
            index: '/v1/events/{event}/certificate-templates',
            download: '/v1/events/{event}/certificate-templates/{template}/download'
        },

        prices: {
            index: '/v1/events/{event}/prices',
            show: '/v1/events/{event}/prices/{price}'
        },

        cancel_conditions: {
            index: '/v1/events/{event}/cancel-conditions',
            show: '/v1/events/{event}/cancel-conditions/{condition}'
        },

        inputs: {
            index: '/v1/events/{event}/inputs',
            show: '/v1/events/{event}/inputs/{input}'
        },

        sponsors: {
            index: '/v1/events/{event}/sponsors',
            show: '/v1/events/{event}/sponsors/{sponsor}'
        },

        feature_elements: {
            index: '/v1/events/{event}/feature-elements'
        },

        impressions: {
            index: '/v1/events/{event}/impressions'
        },

        descriptions: {
            index: '/v1/events/{event}/descriptions',
            show: '/v1/events/{event}/descriptions/{description}',
        },

        products: {
            index: '/v1/events/{event}/products',
            show: '/v1/events/{event}/products/{product}',

            prices: {
                index: '/v1/events/{event}/products/{product}/prices',
                show: '/v1/events/{event}/products/{product}/prices/{price}',
            },

            cancel_conditions: {
                index: '/v1/events/{event}/products/{product}/cancel-conditions',
                show: '/v1/events/{event}/products/{product}/cancel-conditions/{condition}',
            },

            inputs: {
                index: '/v1/events/{event}/products/{product}/inputs',
                show: '/v1/events/{event}/products/{product}/inputs/{input}',
            },

            purchases: {
                index: '/v1/events/{event}/products/{product}/purchases',
                show: '/v1/events/{event}/products/{product}/purchases/{purchase}',
                cancel: '/v1/events/{event}/products/{product}/purchases/{purchase}/cancel',
            },
        },

        purchases: {
            store: '/v1/events/{event}/purchases'
        },

        checkpoints: {
            index: '/v1/events/{event}/checkpoints',
            show: '/v1/events/{event}/checkpoints/{checkpoint}',
        },

        tracks: {
            index: '/v1/events/{event}/tracks',
            show: '/v1/events/{event}/tracks/{track}',
        },

        track_spots: {
            index: '/v1/events/{event}/track-spots'
        },

        participations: {
            index: '/v1/events/{event}/participations',
            store: '/v1/events/{event}/participations',
            create: '/v1/events/{event}/participations/create',
            assign: '/v1/events/{event}/participations/assign',
            show: '/v1/events/{event}/participations/{participation}',
            edit: '/v1/events/{event}/participations/{participation}/edit',
            code: '/v1/events/{event}/participations/{participation}/code',
            store_manual: '/v1/events/{event}/participations/manual',
            update_many: '/v1/events/{event}/participations',

            downloads: {
                confirmation: '/v1/events/{event}/participations/{participation}/downloads/confirmation',
                starter_number: '/v1/events/{event}/participations/{participation}/downloads/starter-number',
            },

            certificates: {
                download_all: '/v1/events/{event}/participations/{participation}/certificates/download',
                download: '/v1/events/{event}/participations/{participation}/certificates/{certificate}/download',
            },

            ranks: {
                index: '/v1/events/{event}/participations/{participation}/ranks',
            },

            live: {
                index: '/v1/events/{event}/participations/live',
            },

            track_spots: {
                index: '/v1/events/{event}/participations/{participation}/track-spots',
            },

            purchases: {
                index: '/v1/events/{event}/participations/{participation}/purchases',
            },

            attempts: {
                store: '/v1/events/{event}/participations/{participation}/attempts',
            },

            orders: {
                index: '/v1/events/{event}/participations/{participation}/orders'
            },

            track_spot_records: {
                index: '/v1/events/{event}/participations/{participation}/track-spot-records'
            }
        },

        social: {
            index: '/v1/events/{event}/social',
            update: '/v1/events/{event}/social'
        },

        payments: {
            index: '/v1/events/{event}/payments',
        },

        features: {
            index: '/v1/events/{event}/features',
            update: '/v1/events/{event}/features',
        },

        coupons: {
            index: '/v1/events/{event}/coupons'
        },
    },

    users: {
        index: '/v1/athletes',
        store: '/v1/athletes',
        show: '/v1/athletes/{user}',
        update: '/v1/athletes/{user}',
        destroy: '/v1/athletes/{user}',

        locale: {
            update: '/v1/athletes/{user}/locale'
        },

        actions: {
            send_confirmation: '/v1/athletes/{user}/actions/send-confirmation'
        },

        clubs: {
            events: '/v1/athletes/{user}/clubs/events'
        },

        jobs: {
            index: '/v1/athletes/{user}/jobs'
        },

        events: {

            participations: {
                index: '/v1/athletes/{user}/events/{event}/participations'
            },
        },

        event_acquisition_campaigns: {
            index: '/v1/athletes/{user}/event-acquisition-campaigns',
        },

        bank_accounts: {
          index: '/v1/athletes/{user}/bank-accounts',
          store: '/v1/athletes/{user}/bank-accounts',
        },

        parents: {
            index: '/v1/athletes/{user}/parents',
            store: '/v1/athletes/{user}/parents',
            update: '/v1/athletes/{user}/parents/{parent}',
            destroy: '/v1/athletes/{user}/parents/{parent}'
        },

        finisherpoints: {
            store: '/v1/athletes/{user}/finisherpoints',

            transactions: {
                index: '/v1/athletes/{user}/finisherpoints/transactions'
            },

            purchases: {
                index: '/v1/athletes/{user}/finisherpoints/purchases',
                store: '/v1/athletes/{user}/finisherpoints/purchases/{product}'
            }
        },

        customer: {
            show: '/v1/athletes/{user}/customer',
            update: '/v1/athletes/{user}/customer',
        },

        payment_sources: {
            credit_cards: {
                index: '/v1/athletes/{user}/payment-sources/credit-cards',
                update: '/v1/athletes/{user}/payment-sources/credit-cards',
                destroy: '/v1/athletes/{user}/payment-sources/credit-cards/detach'
            },

            sepa_debit_cards: {
                index: '/v1/athletes/{user}/payment-sources/sepa-debit-cards',
                destroy: '/v1/athletes/{user}/payment-sources/sepa-debit-cards/detach'
            }
        },

        positions: {
            options: '/v1/athletes/{user}/positions/options',
            show: '/v1/athletes/{user}/positions/{position}',
        },
    },

    clubs: {
        index: '/v1/clubs',
        show: '/v1/clubs/{club}',
        update: '/v1/clubs/{club}',
        request_management: '/v1/clubs/{club}/request-management',

        map: {
            index: '/v2/clubs/map',
            show: '/v1/clubs/map/{club}',
        },

        events: {
            index: '/v1/clubs/{club}/events'
        },

        member_messages: {
            show: '/v1/clubs/{club}/member-messages/{message}'
        },

        job_postings: {
            index: '/v1/clubs/{club}/job-postings',
            show: '/v1/clubs/{club}/job-postings/{posting}',
        },

        social: {
            index: '/v1/clubs/{club}/social',
        },

        account: {
            show: '/v1/clubs/{club}/account'
        },

        members: {
            index: '/v1/clubs/{club}/members',
            auth_requests: '/v1/clubs/{club}/members/requests/auth',
            edit: '/v1/clubs/{club}/members/{member}/edit',
            update: '/v1/clubs/{club}/members/{member}',
            destroy: '/v1/clubs/{club}/members/{member}/cancel',
            contact: '/v1/clubs/{club}/members/{member}/contact',
            assign: '/v1/clubs/{club}/members/assign',

            join: {
                show: '/v1/clubs/{club}/members/join',
                resolve: '/v1/clubs/{club}/members/join/{code}'
            },

            positions: {
                index: '/v1/clubs/{club}/members/{member}/positions',
            },

            messages: {
                index: '/v1/clubs/{club}/members/{member}/messages',
            },
        },

        departments: {
            index: '/v1/clubs/{club}/departments',
        },

        payment_sources: {
            credit_cards: {
                index: '/v1/clubs/{club}/payment-sources/credit-cards'
            },

            sepa_debit_cards: {
                index: '/v1/clubs/{club}/payment-sources/sepa-debit-cards'
            }
        },
    },

    sports: {
        index: '/v1/sports'
    },

    media: {
        files: {
            store: '/v1/media/files',
            download: '/v1/media/files/{media}/download'
        }
    },

    tracker: {
        results: {
            index: '/v1/tracker/{code}/records'
        }
    },

    jobs: {
        index: '/v1/jobs',

        events: {
            index: '/v1/jobs/events'
        },

        clubs: {
            index: '/v1/jobs/clubs'
        },

        applications: {
            store: '/v1/jobs/{job}/applications',
            accept: '/v1/jobs/{job}/applications/accept',
            decline: '/v1/jobs/{job}/applications/decline',
        },

        invitations: {
            store: '/v1/jobs/{job}/invitations',
            accept: '/v1/jobs/{job}/invitations/accept',
            decline: '/v1/jobs/{job}/invitations/decline',
        },

        inputs: {
            index: '/v1/jobs/{job}/inputs',
        },
    },

    user_jobs: {
        index: '/v1/user-jobs',
        update: '/v1/user-jobs/{job}',
        destroy: '/v1/user-jobs/{job}/cancel',
    },

    article_categories: {
        index: '/v1/article-categories',
        show: '/v1/article-categories/{category}',
    },

    event_groups: {
        events: {
            index: '/v1/event-groups/{group}/events',
        },
    },
}
