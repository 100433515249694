import {CookieService} from "@spoferan/nuxt-spoferan/services";
import {defineStore} from 'pinia'
import {settingsPushCookieName} from "@spoferan/nuxt-spoferan/cookies";
import {type ShareSheetProps} from "@spoferan/nuxt-spoferan/types";
import {NativeApp} from "@spoferan/nuxt-spoferan/services";

export const useIndexStore = defineStore('index', {
    state: () => ({
        langModal: false as boolean,

        isHeaderVisible: true as boolean,

        // Whether the user has configured push notifications or the device / user does not support them
        hasConfiguredPushNotifications: true as boolean,

        // Whether the user has configured and enabled the push notifications for the device / user or the device / user does not support them
        hasEnabledPushNotifications: true as boolean,

        // The follow models of the authenticated user
        follows: [] as {type: number, id: number}[],

        isShareSheetVisible: false,
        shareSheetData: undefined as ShareSheetProps | undefined, // Undefined -> not loaded yet
    }),

    actions: {
        toggleLangModal(visible: boolean = true) {
            this.langModal = visible
        },

        async loadFollows() {
            const {$auth, $apiFetch} = useNuxtApp();
            if ($auth.id) {
                const response = await $apiFetch(`/v1/athletes/${$auth.id}/follows`);
                this.follows = response.data;
            } else {
                this.follows = [];
            }
        },

        async initPushNotificationsConfig(): Promise<void> {
            // Push notifications are only supported for registered users
            const {$auth} = useNuxtApp();
            const isSupportedForDevice = NativeApp.isNativeApp() || 'Notification' in window;
            if ($auth.loggedIn && !$auth.isImpersonating && isSupportedForDevice) {
                const pushNotificationSettingsValue = (new CookieService(settingsPushCookieName)).getObjectValue($auth.id);
                if (pushNotificationSettingsValue !== null) {
                    this.hasConfiguredPushNotifications = true;
                    this.hasEnabledPushNotifications = pushNotificationSettingsValue !== false;

                    // Refresh the push device token if the user has enabled push notifications.
                    if (this.hasEnabledPushNotifications) {
                        await this.refreshPushNotificationToken();
                    }
                } else {
                    this.hasConfiguredPushNotifications = false;
                    this.hasEnabledPushNotifications = false;
                }
            } else {
                this.hasConfiguredPushNotifications = true;
                this.hasEnabledPushNotifications = true;
            }
        },

        async refreshPushNotificationToken(): Promise<object> {
            return new Promise(async (resolve, reject) => {
                const {$fire} = useNuxtApp();
                if (NativeApp.isNativeApp()) {
                    await NativeApp.getFirebaseDeviceToken((deviceInfo: {granted: boolean, token: string|null, platform: string}) => {
                        if (deviceInfo.granted) {
                            this.storeDevicePushToken(deviceInfo.token, deviceInfo.platform).then((pushDevice: object) => resolve(pushDevice)).catch((e: any) => reject(e));
                        } else {
                            reject();
                        }
                    });
                } else if ($fire) {
                    try {

                        // GetToken will automatically ask for permission if not already granted
                        const token = await $fire.getMessagingToken()
                        if (token) {
                            this.storeDevicePushToken(token).then((pushDevice: object) => resolve(pushDevice)).catch((e: any) => reject(e));
                        } else {
                            reject();
                        }
                    } catch (e) {
                        console.error("Error while requesting push notification permission. The browser might not support push notifications.", e);
                        reject(e);
                    }
                }
            });
        },

        disablePushNotifications(): void {
            const {$auth} = useNuxtApp();
            (new CookieService(settingsPushCookieName)).expiresInDays(365).setObjectValue($auth.id, false);

            this.hasConfiguredPushNotifications = true;
            this.hasEnabledPushNotifications = false;
        },

        async storeDevicePushToken(token: string, platform?: string, browser?: string): Promise<object> {
            return new Promise(async (resolve, reject) => {
                const {$device, $auth, $apiFetch} = useNuxtApp();

                if (!platform && $device.isNative) {
                    platform = $device.isIos ? 'iOS' : 'Android';
                } else if (!platform) {
                    platform = $device.isMacOs ? 'Mac OS' : ($device.isTablet ? 'Tablet' : ($device.isMobile ? 'Mobile' : 'Desktop'));
                }

                if (!browser && $device.isNative) {
                    browser = 'SPOFERAN App';
                } else if (!browser) {
                    const userAgent = navigator?.userAgent;
                    if (userAgent) {
                        if (userAgent.match(/chrome|chromium|crios/i)) {
                            browser = "Chrome";
                        } else if (userAgent.match(/firefox|fxios/i)) {
                            browser = "Firefox";
                        } else if (userAgent.match(/safari/i)) {
                            browser = "Safari";
                        } else if (userAgent.match(/opr\//i)) {
                            browser = "Opera";
                        } else if (userAgent.match(/edg/i)) {
                            browser = "Edge";
                        }
                    }
                }

                let deviceType = 'desktop';
                if ($device.isTablet) {
                    deviceType = 'tablet';
                } else if ($device.isMobile) {
                    deviceType = 'mobile';
                }

                try {
                    const {data} = await $apiFetch(`/v1/athletes/${$auth.id}/push-devices`, {
                        method: 'POST',
                        body: {
                            device_id: token,
                            platform: platform ?? 'Unbekannt',
                            browser: browser ?? 'Unbekannt',
                            device_type: deviceType,
                        }
                    });

                    (new CookieService(settingsPushCookieName)).expiresInDays(365).setObjectValue($auth.id, token);
                    this.hasConfiguredPushNotifications = true;
                    this.hasEnabledPushNotifications = true;

                    resolve(data);
                } catch (e) {
                    reject(e);
                }
            });
        },

        share(options: ShareSheetProps): void {
            this.$patch({
                shareSheetData: options
            });
            this.isShareSheetVisible = true;
        },
    }

});


