export default defineNuxtRouteMiddleware((to, from) => {

    // Skip middleware on server
    if (process.server) {
        return;
    }

    // Currently, page transitions are buggy on Nuxt 3, see https://github.com/nuxt/nuxt/issues/13309 for more details
    return;

    const {$device} = useNuxtApp();
    if ($device.isDesktop) {
        return;
    }

    const toParamKeys = to.params ? Object.keys(to.params) : [];
    const toPrimaryParamKey = toParamKeys.length > 0 ? toParamKeys[0] : '';
    const fromParamKeys = from?.params ? Object.keys(from.params) : [];
    const fromPrimaryParamKey = fromParamKeys.length > 0 ? fromParamKeys[0] : '';

    // We use the View Transition API for model detail pages when supported
    if (document.startViewTransition) {
        const viewTransitionPageParams = ['article', 'sport']
        if (viewTransitionPageParams.includes(toPrimaryParamKey) || (viewTransitionPageParams.includes(fromPrimaryParamKey) && fromPrimaryParamKey !== toPrimaryParamKey)) {
            return;
        }
    }

    // Currently there is a transition bug when navigating within or from a nested page group whereby we exclude these pages
    // See https://github.com/nuxt/nuxt/issues/13309 for more details
    const pageNavigationExcludeParams = ['user', 'event', 'club']
    const pageNavigationExcludePathPrefix = ['/pay/', '/my/', '/login']
    if (pageNavigationExcludeParams.includes(fromPrimaryParamKey) || pageNavigationExcludeParams.includes(toPrimaryParamKey) || pageNavigationExcludePathPrefix.some(prefix => to.path.startsWith(prefix)) || (from && pageNavigationExcludePathPrefix.some(prefix => from.path.startsWith(prefix)))) {
        return;
    }

    const getDepth = (path: string): number => {
        const mainEntryPaths = ['/dashboard', '/search', '/notifications', '/my', '/', '/login', '/register'];
        if (mainEntryPaths.includes(path)) {
            return 0;
        }

        return path.split('/').filter(seg => seg.length > 0).length;
    }

    const toDepth = getDepth(to.path);
    const fromDepth = getDepth(from.path);

    if (toDepth > fromDepth) {
        to.meta.pageTransition = { name: 'mobile-page-left-transition' };
        from.meta.pageTransition = { name: 'mobile-page-left-transition' };
    } else if (toDepth < fromDepth) {
        to.meta.pageTransition = { name: 'mobile-page-right-transition' };
        from.meta.pageTransition = { name: 'mobile-page-right-transition' };
    }
})