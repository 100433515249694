import validate from "/home/runner/work/app/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import page_45global from "/home/runner/work/app/app/src/middleware/page.global.ts";
import redirect_45trailing_45slash_46global from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/middlewares/redirect-trailing-slash.global.mjs";
import redirect_46global from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/middlewares/redirect.global.mjs";
import auth_46global from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/middlewares/auth.global.mjs";
import manifest_45route_45rule from "/home/runner/work/app/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  page_45global,
  redirect_45trailing_45slash_46global,
  redirect_46global,
  auth_46global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  guest: () => import("/home/runner/work/app/app/src/middleware/guest.ts"),
  login: () => import("/home/runner/work/app/app/src/middleware/login.ts"),
  tabs: () => import("/home/runner/work/app/app/src/middleware/tabs.ts")
}