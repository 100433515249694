export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const firebaseConfig = config.public.firebase;

    const fire = {
        async getApp() {
            if (!this.app) {
                const { initializeApp } = await import('firebase/app')
                this.app = initializeApp(firebaseConfig);
            }
            return this.app
        },

        async getMessagingToken() {
            if (!this.messaging) {
                const { getMessaging, getToken } = await import('firebase/messaging')
                const firebaseApp = await this.getApp()
                this.messaging = getMessaging(firebaseApp);
                this.getToken = getToken;
            }
            return this.getToken(this.messaging, {vapidKey: firebaseConfig.fcmPublicVapidKey})
        }
    }

    return {
        provide: {
            fire: fire
        }
    }
})
