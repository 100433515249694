import type {RouterOptions} from "vue-router";
import {deepEqual} from "@spoferan/spoferan-ts-core";

export default <RouterOptions>{
    scrollBehavior(to, from, savedPosition) {
        const nuxtApp = useNuxtApp()

        const pageHookName = to.meta.pageTransition?.name === 'mobile-page-left-transition' ? 'page:transition:finish' : 'page:finish';

        return new Promise(async (resolve) => {
            nuxtApp.hooks.hookOnce(pageHookName, async () => {
                await nextTick()

                let hash = to.hash;

                // On mobile pages, we always show the profile headers on every page for improved SEO and less navigation flicker.
                // But, we do not want to scroll to the top of the profile header as the user is interested in the main content.
                // Therefore, on mobile devices, we always scroll to the top of the content navigation tabs when changing pages.
                if (!hash && !nuxtApp.$device.isDesktop) {
                    const profilePageParamKeys = ['user', 'event', 'club'];
                    const isNavigatingWithinProfilePage = profilePageParamKeys.some(key => to.params[key] && from.params[key] && to.params[key] === from.params[key]);
                    if (isNavigatingWithinProfilePage) {
                        hash = '#tabs';
                    }
                }

                // If a specific text shall be focused, we use the default browser logic
                // This might be the case when the user is referred by search engines
                const isTextSelectorHash = hash && hash.startsWith('#:~:text');
                if (isTextSelectorHash) {
                    return;
                }

                // If a hash is specified, we smoothly scroll to the hash
                if (hash) {
                    const element = document.querySelector(hash);
                    if (element) {
                        const box = element.getBoundingClientRect();
                        const body = document.body;
                        const docEl = document.documentElement;
                        const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
                        const clientTop = docEl.clientTop || body.clientTop || 0;
                        const top = box.top + scrollTop - clientTop;

                        resolve({
                            top: top - 56, // -56 for navbar -> this is strictly required for our mobile profile page tab focus scrolling logic.
                            behavior: 'smooth'
                        });
                        return;
                    }
                }

                // If the path to the last route change is the same, we do not scroll as only some query params changed (or the hash that is handled above)
                if (from && to && from.path === to.path) {
                    return;
                }

                // SavedPosition is only available for popstate navigations (back button) on which we want to restore the scroll position when the user clicked the link
                if (savedPosition) {
                    resolve(savedPosition);
                    return
                }

                // Scroll smoothly to top on child pages
                if (to.params && from.params && deepEqual(to.params, from.params)) {
                    const element = document.querySelector('#content');
                    if (element) {
                        const headerOffset = 98;  // -98 due to navbar + potential sticky toolbar
                        const box = element.getBoundingClientRect();
                        const elementDistanceToHeader = box.top - headerOffset;

                        // We only scroll to the content, if we are below it
                        if (elementDistanceToHeader < 0) {
                            const body = document.body;
                            const docEl = document.documentElement;
                            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
                            const top = elementDistanceToHeader + scrollTop;

                            resolve({
                                top: top - 8, // Some padding
                                behavior: 'smooth'
                            });
                            return;

                        }

                        return;
                    }

                    resolve({
                        top: 0,
                        behavior: 'smooth'
                    });
                    return;
                }

                // Scroll to top on all non child pages
                resolve({top: 0});
            });
        });
    },
};
