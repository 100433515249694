export default defineNuxtPlugin(() => {
    return {
        provide: {
            /**
             * Checks if the specified models are the same.
             *
             * @param model
             * @param compareModel
             * @returns {boolean}
             */

            isModel: function (model, compareModel) {
                if (!model || !compareModel) {
                    return false
                }

                return model.model_name === compareModel.model_name && model.id === compareModel.id
            }
        }
    }
})