<template>
	<app>
		<NuxtLayout>
			<app-app-header v-if="statusCode !== 503" flat-on-top/>
			<full-screen-page :icon="icons.warning" color="warning" :title="errorTitle">
				<div class="text-center mb-0 w-full leading-wide" v-html="errorMessage"/>
				<div v-if="stackTrace && showStackTrace" v-html="stackTrace" class="overflow-x-auto"/>

				<wrapper v-if="!isOnline || statusCode === 503" class="mt-24 mb-24" stretch>
					<btn @click="reloadPage" depressed>
						<icon :icon="icons.refresh"></icon>
						<span>{{ $t('action.refresh') }}</span>
					</btn>
				</wrapper>
				<wrapper v-else-if="statusCode > 399" class="mt-24 mb-24" stretch>
					<btn @click="handleErrorReset">
						<icon :icon="icons.back"></icon>
						<span>{{ $t('action.back') }}</span>
					</btn>
					<btn v-if="stackTrace" v-model="showStackTrace" @click="showStackTrace = !showStackTrace">
						<span>DEBUG</span>
					</btn>
					<btn v-if="$auth.loggedIn" :to="$web('dashboard')" color="primary">
						<span>{{ $t('action.to_start_page') }}</span>
					</btn>
					<btn v-else :to="{path: $web('auth.login'), query: {redirect: $route.fullPath}}" color="primary">
						<span>{{ $t('action.login') }}</span>
					</btn>
				</wrapper>
			</full-screen-page>
		</NuxtLayout>
	</app>
</template>

<script setup lang="ts">
import {isString} from "@spoferan/spoferan-ts-core";
import { useOnline } from '@vueuse/core'
import {backIcon} from "@spoferan/nuxt-spoferan/icons";
import {refreshIcon} from "@spoferan/nuxt-spoferan/icons";
import {warningIcon} from "@spoferan/nuxt-spoferan/icons";

const props = defineProps({
	error: Object
})

const router = useRouter();
const config = useRuntimeConfig();
const {t} = useI18n();
const isDebug = config.public.debug === true

const isOnline = useOnline();

const statusCode = (props.error && props.error.statusCode) || 500;
const errorTitle = !isOnline.value ? t('error.offline.title') : t(`error.${statusCode}.title`);
const stackTrace = isDebug ? props.error.stack : null;
const showStackTrace = ref(false);

const showServerMessageResponseCodes = [404, 403];

const errorMessage = computed(() => {
	if (((isDebug && showStackTrace.value) || showServerMessageResponseCodes.indexOf(statusCode) > 0) && props.error.message && isString(props.error.message) && props.error.message !== 'This page could not be found') {
		return props.error.message;
	}

	return !isOnline.value ? t('error.offline.message') : t(`error.${statusCode}.message`)
});

const icons = {
	warning: warningIcon,
	back: backIcon,
	refresh: refreshIcon,
}

const handleErrorReset = () => {
	clearError();
	router.go(-1);
}

const reloadPage = () => {
	window.location.reload(true)
}

// This will be reactive even you change title/description above
useHead({
	htmlAttrs: {
		lang: 'de'
	},

	title: 'SPOFERAN - Mein Sportnetzwerk',

	meta: [
		{charset: 'utf-8'},
		{name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0 maximum-scale=1.0'},
		{name: 'author', content: 'SPOFERAN GmbH'},
		{name: 'theme-color', content: '#008200'},

		// Fix for IE 11 rendering issues
		{
			'http-equiv': 'X-UA-Compatible',
			'content': 'IE=edge'
		},

		// Activate iOS PWA Splash Screen
		{name: 'apple-mobile-web-app-capable', content: 'yes'}
	],
	link: [
		{rel: 'shortcut icon', type: 'image/x-icon', href: '/images/favicons/favicon.ico'},
		{rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/images/favicons/apple-touch-icon.png'},
		{rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/favicons/favicon-32x32.png'},
		{rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/favicons/favicon-16x16.png'},
		{rel: 'mask-icon', type: 'image/png', color: '#008200', href: '/images/favicons/safari-pinned-tab.svg'}
	]
})
</script>