import revive_payload_client_4sVQNw7RlN from "/home/runner/work/app/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/app/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/app/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/app/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/app/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/app/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/app/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/app/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/app/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/home/runner/work/app/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import auth_o9Y7hBcZWV from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/auth.mjs";
import sentry_client_kFCxjQ1mIG from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/sentry.client.mjs";
import device_unVtBVDjdw from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/device.mjs";
import breakpoints_abBlVVWZ2T from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/breakpoints.mjs";
import routes_GohGqgNu3V from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/routes.mjs";
import fetch_fnvpHeUwLv from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/fetch.mjs";
import analytics_client_NVnzuowPzc from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/analytics.client.mjs";
import image_0jnDvuDwJR from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/image.mjs";
import date_SDhRzEw4YL from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/date.mjs";
import directives_MGoh2xuoPH from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/directives.mjs";
import meta_83E8Ec6U8Q from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/meta.mjs";
import i18n_kDXbqsT6Q5 from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/i18n.mjs";
import echo_client_OV3DifJxzq from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/echo.client.mjs";
import alert_client_nbu1cPUMQ0 from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/alert.client.mjs";
import notify_client_AwxHzlZI8X from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/notify.client.mjs";
import helpers_client_dmQMo07LuD from "/home/runner/work/app/app/node_modules/@spoferan/nuxt-spoferan/dist/runtime/plugins/helpers.client.mjs";
import i18n_yfWm7jX06p from "/home/runner/work/app/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/app/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import config_5kDsWc9Aim from "/home/runner/work/app/app/src/plugins/config.ts";
import firebase_client_lOvfsw6Ppv from "/home/runner/work/app/app/src/plugins/firebase.client.ts";
import functions_65OoAKduN0 from "/home/runner/work/app/app/src/plugins/functions.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  auth_o9Y7hBcZWV,
  sentry_client_kFCxjQ1mIG,
  device_unVtBVDjdw,
  breakpoints_abBlVVWZ2T,
  routes_GohGqgNu3V,
  fetch_fnvpHeUwLv,
  analytics_client_NVnzuowPzc,
  image_0jnDvuDwJR,
  date_SDhRzEw4YL,
  directives_MGoh2xuoPH,
  meta_83E8Ec6U8Q,
  i18n_kDXbqsT6Q5,
  echo_client_OV3DifJxzq,
  alert_client_nbu1cPUMQ0,
  notify_client_AwxHzlZI8X,
  helpers_client_dmQMo07LuD,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  config_5kDsWc9Aim,
  firebase_client_lOvfsw6Ppv,
  functions_65OoAKduN0
]