import ApiRoutes from '~/config/api-routes';
import WebRoutes from '~/config/web-routes';
import ResultasticRoutes from '~/config/resultastic-routes';
import {parseRoute, parseVueNamedRoute} from "@spoferan/spoferan-ts-core";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const router = useRouter();

    return {
        provide: {
            api: (name: string, params = {}, absolute: boolean = false) => {
                return parseRoute(ApiRoutes, name, params, null, absolute ? config.public.apiUrl : null)
            },

            // Deprecated, use app instead for native named routes and passing params as props support
            web: (name: string, params = {}, absolute: boolean = false) => {
                const locale = !nuxtApp.$i18n || nuxtApp.$i18n.locale.value === nuxtApp.$i18n.defaultLocale ? null : nuxtApp.$i18n.locale.value;

                return parseRoute(WebRoutes, name, params, locale, absolute ? config.public.appUrl : null)
            },
            resultastic: (name: string, params = {}) => {
                return parseRoute(ResultasticRoutes, name, params, null, config.public.resultasticUrl)
            },
            app: (name: string, params = {}, options: {absolute: boolean, locale: string|null} = {absolute: false, locale: null}) => {
                const locale = options.locale ?? (!nuxtApp.$i18n ? null : nuxtApp.$i18n.locale.value);

                return parseVueNamedRoute(router, name, params, locale, options.absolute ? config.public.appUrl : null)
            }
        }
    }
})
